<template>
  <div class="PhysicianVisitsTow">
    <h3>您的身体健康情况描述</h3>
    <div class="boxp">
      <div class="fxk padd-30">
        <p class="titles">是否做过眼科手术？</p>
        <el-checkbox-group v-model="checkList" class="sh" @change="bh()">
          <div class="sh padd-30 w">
            <el-checkbox :label="0"></el-checkbox>
            <p>左眼</p>
          </div>
          <div class="sh w">
            <el-checkbox :label="1"></el-checkbox>
            <p>右眼</p>
          </div>
          <div class="sr-boxs">
            <el-input v-show="checkList.length > 0" v-model="postData.InquiryEyeOperationDes" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="checkList.length > 0" v-model="postData.InquirySicknessDuration1" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-checkbox-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有眼病史？</p>
        <el-radio-group v-model="yb" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-model="postData.InquiryCardiovascularDes" v-show="yb" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-model="postData.InquirySicknessDuration2" v-show="yb" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有过敏？</p>
        <el-radio-group v-model="gm" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="gm" v-model="postData.InquiryHighMyopiaDes" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="gm" v-model="postData.InquirySicknessDuration3" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有家族遗传史？</p>
        <el-radio-group v-model="yc" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="yc" v-model="postData.InquiryHomeSickDes" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="yc" v-model="postData.InquirySicknessDuration4" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有高血压、血糖偏高的现象？</p>
        <el-radio-group v-model="xt" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="xt" v-model="postData.InquiryHBGHBPDes" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="xt" v-model="postData.InquirySicknessDuration5" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有心脏及心脑血管疾病？</p>
        <el-radio-group v-model="xg" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="xg" v-model="postData.InquiryDiabetesDes" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="xg" v-model="postData.InquirySicknessDuration6" class="sr" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>
    </div>

    <div style="display: flex; align-items: center; padding: .2rem 3vw">
      <p>既往屈光矫正方式：</p>
      <el-checkbox-group v-model="qgjws">
        <el-checkbox :label="0">框架眼镜</el-checkbox>
        <el-checkbox :label="1">软性角膜接触镜</el-checkbox>
        <el-checkbox :label="2">RGP</el-checkbox>
        <el-checkbox :label="3">OK镜片</el-checkbox>
        <el-checkbox :label="4">屈光手术</el-checkbox>
        <el-checkbox :label="5">无</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="bz">
      <p>现病史：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.InquiryOther">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhysicianVisitsTow",
  data() {
    return {
      checkList: [],
      sr: '',
      textarea2: '',
      idx: [0,0,0,0,0,0],
      postData: {
        InquiryCardiovascular: '',
        InquiryHighMyopia: '',
        InquiryHomeSick: '',
        InquiryHBGHBP: '',
        InquiryDiabetes: '',
        InquiryNeeds: '',
        InquiryEyeOperation: '',
        InquiryEyeOperationDes: "",
        InquiryCardiovascularDes: '',
        InquiryHighMyopiaDes: '',
        InquiryHomeSickDes: '',
        InquiryHBGHBPDes: '',
        InquiryDiabetesDes: '',
        InquirySicknessDuration1: '',
        InquirySicknessDuration2: '',
        InquirySicknessDuration3: '',
        InquirySicknessDuration4: '',
        InquirySicknessDuration5: '',
        InquirySicknessDuration6: '',
        InquiryOther: ''
      },
      qgjws: [],
      yb: '',
      gm: '',
      yc: '',
      xt: '',
      xg: ''
    }
  },
  watch: {
    postData: {
      handler(n , o) {
        this.$store.commit('physicianVisits/UpAldult', {
          type: 2,
          obj: n
        })
      },
      immediate: true,
      deep: true
    },
    "$store.state.physicianVisits.isgetData": function (n, o) {
      this.Initialize()
    },
    // checkList: {
    //   deep: true,
    //   handler(n, o) {
    //     if (o.length) {
    //
    //     }
    //   }
    // },
    /*'$store.state.physicianVisits.xzConsulting': function (n, o) {
      if (n) {
        this.Initialize()
      }
    },*/
    /*'$store.state.users.CsUser': function (n, o) {
      if (!n) {
        this.checkList = []
        this.postData = {}
        this.yb = ''
        this.gm = ''
        this.yc = ''
        this.xt = ''
        this.xg = ''
      }
    },*/
    yb: function () {
      let arr = []
      if (this.yb) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      this.postData.InquiryCardiovascular = arr
    },
    gm: function () {
      let arr = []
      if (this.gm) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      this.postData.InquiryHighMyopia = arr
    },
    yc: function () {
      let arr = []
      if (this.yc) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      this.postData.InquiryHomeSick = arr
    },
    xt: function () {
      let arr = []
      if (this.xt) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      this.postData.InquiryHBGHBP = arr
    },
    xg: function () {
      let arr = []
      if (this.xg) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      this.postData.InquiryDiabetes = arr
    },
    qgjws: function (n, o) {
      let arr = [0, 0, 0, 0, 0, 0]
      for (let i = 0; i<n.length; i++) {
        arr[n[i]] = 1
      }
      this.postData.InquiryNeeds = arr
    }
  },
  created() {

  },
  methods: {
    bh() {
      let arr = [false, false]
      for (let i = 0; i < this.checkList.length; i++) {
        arr[this.checkList[i]] = true
      }
      // arr.forEach(item => {
      //   arr[item] = true
      // })
      // if (this.checkList.length > 0) {
      //   this.idx.splice(0,1,1)
      // }else {
      //   this.idx.splice(0,1,0)
      // }
      // console.log(arr.join(','))
      this.postData.InquiryEyeOperation = arr
    },
    Initialize() {
      this.yb = 0
      this.gm = 0
      this.yc = 0
      this.xt = 0
      this.xg = 0
      this.checkList = []
      this.qgjws = []
      for (let key in this.postData) {
        this.postData[key] = this.$store.state.physicianVisits.datas[key]
      }
      //console.log( this.postData)
      if (this.postData.InquiryEyeOperation && this.postData.InquiryEyeOperation.length > 0) {
        let arr = this.postData.InquiryEyeOperation
        let item = []
        for (let j = 0; j < arr.length; j++) {
          if (arr[j]) {
            item.push(j)
          }
        }
        this.checkList = item
      }
      if (this.postData.InquiryCardiovascular && this.postData.InquiryCardiovascular.length > 0) {
        if (this.postData.InquiryCardiovascular[0]) {
          this.yb = 1
        }else {
          this.yb = 0
        }
      }
      if (this.postData.InquiryHighMyopia && this.postData.InquiryHighMyopia.length > 0) {
        if (this.postData.InquiryHighMyopia[0]) {
          this.gm = 1
        }else {
          this.gm = 0
        }
      }
      if (this.postData.InquiryHomeSick && this.postData.InquiryHomeSick.length > 0) {
        if (this.postData.InquiryHomeSick[0]) {
          this.yc = 1
        }else {
          this.yc = 0
        }
      }
      if (this.postData.InquiryHBGHBP && this.postData.InquiryHBGHBP.length > 0) {
        if (this.postData.InquiryHBGHBP[0]) {
          this.xt = 1
        }else {
          this.xt = 0
        }
      }
      if (this.postData.InquiryDiabetes && this.postData.InquiryDiabetes.length > 0) {
        if (this.postData.InquiryDiabetes[0]) {
          this.xg = 1
        }else {
          this.xg = 0
        }
      }
      this.qgjws = this.clarr(this.postData.InquiryNeeds)
    },

    clarr(arr) {
      if (!arr) return []
      let item = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]) item.push(i)
      }
      return item
    }
  }
}
</script>

<style scoped lang="scss">
  .PhysicianVisitsTow {
    padding: 2vh 0;
  }
  .sh {
    display: flex;
    align-items: center;
    p {padding-left: 0.1rem}
  }
  ::v-deep .fxk  .el-checkbox-group {font-size: 14px;}
  ::v-deep .fxk  .el-checkbox__inner::after {
    width: 6px;
    height: 14px;
    left: 8px;
    top: 2px;
  }
  ::v-deep .fxk  .el-checkbox__inner {
    width: 25px;
    height: 25px;
  }
  ::v-deep .fxk  .el-checkbox__label {display: none;}
  .nr-box {
    img {
      width: 10vw;
      border-radius: 5px;
    }
  }
  .boxp {
    width: 82vw;
    padding: 0 3vw;
    display: flex;
    flex-wrap: wrap;
  }
  .sr {width: 2rem; margin-right: .1rem}
  .padd-30 {padding-right: 0.5rem}
  .titles {
    padding-top: 0.25rem;
    text-align: left;
  }
  .w {width: 0.9rem}
  .bz {
    width: 76vw;
    padding: 0 3vw;
    text-align: left;
    padding-top: 0.3rem;
  }
  .sr-boxs {
    width: 4rem;
    height: 40px;
    margin-left: 0.2rem;
    display: flex;
  }
</style>
